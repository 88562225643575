<template>
  <v-dialog
    v-model="dialog"
    max-width="300px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        v-bind="attrs"
        v-on="on"
      >
        Edit
      </v-btn>
    </template>
    <v-card>
      <v-card-title>Product</v-card-title>
      <v-card-text>
        <v-checkbox
          v-model="inputs.active"
          :error-messages="errors.active"
          label="Active"
        />
        <v-checkbox
          v-model="inputs.coverToBeGenerated"
          :error-messages="errors.coverToBeGenerated"
          label="Cover to be generated"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="secondary"
          text
          @click="dialog = false"
        >
          Close
        </v-btn>
        <v-btn
          color="primary"
          text
          :loading="loading"
          @click="save"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'TechnicalReviewsDialog',
  props: {
    technicalReview: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      dialog: false,
      inputs: {},
      errors: {},
    }
  },
  mounted() {
    this.inputs.active = this.technicalReview.active
    this.inputs.coverToBeGenerated = this.technicalReview.coverToBeGenerated
  },
  methods: {
    save() {
      this.loading = true
      this.axios.put(`technical-reviews/${this.technicalReview.id}`, {
        ...this.inputs,
      }).then(response => {
        this.$emit('update:technical-review', response.data.data)
        this.dialog = false
      }).catch(error => {
        this.errors = error.response.data?.errors
      }).finally(() => {
        this.loading = false
      })
    },
  },
}
</script>
