<template>
  <div v-if="technicalReview">
    <v-card class="mb-3">
      <v-card-title class="headline lighten-2">
        <span v-if="technicalReview.technicalReviewLanguage">
          {{ technicalReview.technicalReviewLanguage[0].label }}
        </span>
        <v-spacer />
        <div v-if="technicalReview.id !== undefined">
          <technical-review-dialog
            v-if="technicalReview.id !== undefined"
            :technical-review.sync="technicalReview"
          />
        </div>
      </v-card-title>
      <v-divider />
      <v-row>
        <v-col cols="8">
          <v-card-text class="font-weight-bold">
            <div>{{ technicalReview.technicalReviewLanguage[0].title1 }}</div>
            <div>{{ technicalReview.technicalReviewLanguage[0].title2 }}</div>
          </v-card-text>
          <v-divider />
          <v-card-text>
            <div>ID: <span class="font-weight-bold">{{ technicalReview.id }}</span></div>
            <div>External ID: <span class="font-weight-bold">{{ technicalReview.externalId }}</span></div>
            <div>ETAI ID: <span class="font-weight-bold">{{ technicalReview.etaiIdModel }}</span></div>
            <div>Haynes ID: <span class="font-weight-bold">{{ technicalReview.haynesIdModel }}</span></div>
            <div>Reference: <span class="font-weight-bold">{{ technicalReview.reference }}</span></div>
            <div>
              Active:
              <active-badge :status="technicalReview.active" />
            </div>
            <div>Creation: <span class="font-weight-bold">{{ technicalReview.createdAt | date }}</span></div>
            <div>Last update: <span class="font-weight-bold">{{ technicalReview.updatedAt | date }}</span></div>

            <div>
              Type:
              <span
                v-if="technicalReview.technicalReviewType"
                class="font-weight-bold"
              >
                {{ technicalReview.technicalReviewType.label }}
              </span>
            </div>
          </v-card-text>
        </v-col>
        <v-col cols="4">
          <v-hover v-slot="{ hover }">
            <v-img
              contain
              max-height="300"
              :src="technicalReview.technicalReviewLanguage[0].coverUrl"
            >
              <v-slide-x-reverse-transition>
                <v-img
                  v-show="hover"
                  contain
                  max-height="300"
                  :src="technicalReview.coverShoppingUrl"
                />
              </v-slide-x-reverse-transition>
              <template v-slot:placeholder>
                <v-card
                  class="fill-height d-flex align-center justify-center mx-3"
                  outlined
                  color="#ddd"
                >
                  <v-icon
                    color="white"
                    aria-hidden="true"
                    size="6em"
                  >
                    mdi-car-hatchback
                  </v-icon>
                </v-card>
              </template>
            </v-img>
          </v-hover>
        </v-col>
      </v-row>
    </v-card>

    <div v-if="technicalReview.id !== undefined">
      <div
        v-for="vehicle in technicalReview.technicalReviewVehicle"
        :key="vehicle.vehicle.id"
      >
        <v-card
          class="mb-3"
          height="100"
        >
          <v-row>
            <v-col
              class="py-0"
              cols="2"
            >
              <v-img
                height="100"
                class="rounded-l"
                :src="vehicle.vehicle.etaiPicture"
              />
            </v-col>
            <v-col
              cols="4"
            >
              <div v-if="vehicle.vehicle.vehicleLanguage[0]">
                <div class="font-weight-medium body-1">
                  {{ vehicle.vehicle.vehicleLanguage[0].brandName }}
                  {{ vehicle.vehicle.vehicleLanguage[0].modelName }}
                </div>
                <div
                  class="text--secondary body-2"
                >
                  {{ vehicle.vehicle.vehicleLanguage[0].body }}
                  -
                  {{ vehicle.vehicle.doorNumber }} doors
                </div>
                <div class="text-caption mt-3">
                  From
                  {{ vehicle.vehicle.vehicleLanguage[0].startDate | date('DD/MM/YYYY') }}
                  <span v-if="vehicle.vehicle.vehicleLanguage[0].endDate">
                    to {{ vehicle.vehicle.vehicleLanguage[0].endDate | date('DD/MM/YYYY') }}
                  </span>
                </div>
              </div>
            </v-col>
            <v-col
              cols="6"
              class="py-0"
            >
              <v-row class="text-center pt-1">
                <v-col>
                  <div class="text-overline grey--text text--darken-1">
                    ID
                  </div>
                  <div class="text-h5 font-weight-light">
                    {{ vehicle.vehicle.id }}
                  </div>
                </v-col>
                <v-col>
                  <div class="text-overline grey--text text--darken-1">
                    ETAI ID
                  </div>
                  <div class="text-h5 font-weight-light">
                    {{ vehicle.vehicle.etaiVariantId }}
                  </div>
                </v-col>
                <v-col>
                  <div class="text-overline grey--text text--darken-1">
                    Haynes ID
                  </div>
                  <div class="text-h5 font-weight-light">
                    {{ vehicle.vehicle.haynesVehicleId }}
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </div>

      <technical-review-vehicle-dialog
        :technical-review="technicalReview"
        @update:technical-review-vehicles="getTechnicalReview"
      />
    </div>
  </div>
</template>

<script>
import ActiveBadge from '@/views/ui/ActiveBadge'
import TechnicalReviewDialog from '@/views/TechnicalReviewDialog'
import TechnicalReviewVehicleDialog from '@/views/TechnicalReviewVehicleDialog'

export default {
  name: 'TechnicalReviewDetails',
  components: {
    ActiveBadge,
    TechnicalReviewDialog,
    TechnicalReviewVehicleDialog,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    currentTechnicalReview: {
      type: Object,
      required: false,
      default: () => {
        return {
          id: null,
        }
      },
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      tab: null,
      technicalReview: null,
    }
  },
  mounted() {
    if (this.currentTechnicalReview.id === null) {
      this.getTechnicalReview()
    } else {
      this.technicalReview = this.currentTechnicalReview
    }
  },
  methods: {
    getTechnicalReview() {
      this.loading = true
      this.axios.get(`technical-reviews/${this.id}`).then(response => {
        this.technicalReview = response.data.data
      }).catch(error => {
        console.error(error)
      }).finally(() => {
        this.loading = false
      })
    },
  },
}
</script>
