<template>
  <v-dialog
    v-model="dialog"
    max-width="300px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        small
        v-bind="attrs"
        v-on="on"
      >
        Import variants
      </v-btn>
    </template>
    <v-card>
      <v-card-title>Import variants</v-card-title>
      <v-card-text>
        <v-file-input
          v-model="inputs.technicalReviewsVariantFile"
          accept=".csv"
          :error-messages="errors.technicalReviewsVariantFile"
          label="Variants file"
          placeholder="Import.csv"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="secondary"
          text
          @click="dialog = false"
        >
          Close
        </v-btn>
        <v-btn
          color="primary"
          text
          :loading="loading"
          :disabled="!inputs.technicalReviewsVariantFile"
          @click="submit"
        >
          Import
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'TechnicalReviewsImportDialog',
  data() {
    return {
      dialog: false,
      loading: false,
      inputs: {},
      errors: {},
    }
  },
  methods: {
    submit() {
      this.loading = true
      let formData = new FormData()
      formData.append('technicalReviewsVariantFile', this.inputs.technicalReviewsVariantFile)
      this.axios.post(`technical-reviews/imports`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then(response => {
        this.$emit('import:technical-reviews-variants')
        this.dialog = false
      }).catch(error => {
        this.errors = error.response.data?.errors
      }).finally(() => {
        this.loading = false
      })
    },
  },
}
</script>
