<template>
  <div>
    <v-row>
      <v-col class="text-h5">
        Products
      </v-col>
      <v-col class="text-right">
        <span>
          <technical-reviews-import-variants-dialog
            @import:technical-reviews-variants="getTechnicalReviews"
          />
        </span>
        <span class="ml-2">
          <technical-reviews-import-dialog
            @import:technical-reviews="getTechnicalReviews"
          />
        </span>
      </v-col>
    </v-row>

    <div>
      <v-select
        v-model="query.perPage"
        class="d-inline-block"
        :disabled="loading"
        :items="[5, 10, 20, 50,100,200,500]"
        label="Items per page"
        dense
      />
    </div>

    <v-data-table
      :headers="headers"
      :items="technicalReviews"
      class="elevation-1"
      :loading="loading"
      hide-default-footer
      :page.sync="query.page"
      :items-per-page="query.perPage"
    >
      <template v-slot:item.active="{ item }">
        <active-badge :status="item.active" />
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
          small
          :to="{ name: 'products.details', params: { id: item.id.toString(), currentTechnicalReview: item }}"
        >
          Details
        </v-btn>
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination
        v-model="query.page"
        :disabled="loading"
        :length="meta.last_page"
        total-visible="7"
      />
    </div>
  </div>
</template>

<script>
import { cleanObject } from '@/helpers/utils'
import ActiveBadge from '@/views/ui/ActiveBadge'
import TechnicalReviewsImportDialog from '@/views/TechnicalReviewsImportDialog'
import TechnicalReviewsImportVariantsDialog from '@/views/TechnicalReviewsImportVariantsDialog'

export default {
  name: 'TechnicalReviews',
  components: {
    ActiveBadge,
    TechnicalReviewsImportDialog,
    TechnicalReviewsImportVariantsDialog,
  },
  data() {
    return {
      loading: false,
      headers: [
        {
          text: 'ID',
          value: 'id',
          sortable: true,
        },
        {
          text: 'SKU',
          value: 'reference',
          sortable: true,
        },
        {
          text: 'External ID',
          value: 'technicalReview.externalId',
          sortable: true,
        },
        {
          text: 'Type',
          value: 'technicalReviewType.label',
          sortable: true,
        },
        {
          text: 'Marque',
          value: 'technicalReviewVehicle[0].vehicle.vehicleLanguage[0].brandName',
          sortable: true,
        },
        {
          text: 'Titre',
          value: 'technicalReviewLanguage[0].label',
          sortable: true,
        },
        {
          text: 'Active',
          value: 'active',
          sortable: true,
        },
        {
          text: null,
          value: 'actions',
          sortable: false,
          align: 'right',
        },
      ],
      queryFilters: {},
      query: {
        page: 1,
        perPage: 10,
      },
      technicalReviews: [],
      meta: {},
    }
  },
  watch: {
    'query.page'() {
      this.getTechnicalReviews()
    },
    'query.perPage'() {
      this.query.page = 1
      this.getTechnicalReviews()
    },
  },
  mounted() {
    this.getTechnicalReviews()
  },
  methods: {
    getTechnicalReviews() {
      this.loading = true

      this.axios.get('technical-reviews', {
        params: {
          page: this.query.page,
          resultsPerPage: this.query.perPage,
          ...cleanObject(this.queryFilters),
        },
      }).then(response => {
        this.technicalReviews = response.data.data
        this.meta = response.data.meta
      }).catch(error => {
        console.error(error)
      }).finally(() => {
        this.loading = false
      })
    },
  },
}
</script>
