<template>
  <v-dialog
    v-model="dialog"
    max-width="300px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        block
        color="white"
        class="mb-3"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon left>
          mdi-plus-circle
        </v-icon>
        Add a vehicle
      </v-btn>
    </template>
    <v-card>
      <v-card-title>Add a vehicle</v-card-title>
      <v-card-text>
        <v-text-field
          v-model="inputs.vehicleId"
          :error-messages="errors.vehicleId"
          label="Vehicle ID"
          placeholder="000000"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="secondary"
          text
          @click="dialog = false"
        >
          Close
        </v-btn>
        <v-btn
          color="primary"
          text
          :loading="loading"
          @click="submit"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'TechnicalReviewVehicleDialog',
  props: {
    technicalReview: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      inputs: {},
      errors: {},
    }
  },
  methods: {
    submit() {
      this.loading = true
      this.axios.post(`technical-reviews/vehicles`, {
        technicalReviewId: this.technicalReview.id,
        ...this.inputs,
      }).then(response => {
        this.$emit('update:technical-review-vehicles', response.data.data)
        this.dialog = false
      }).catch(error => {
        this.errors = error.response.data?.errors
      }).finally(() => {
        this.loading = false
      })
    },
  },
}
</script>
